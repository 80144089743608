
  import { computed, ref ,defineComponent } from 'vue';

  import Campo from '@/components/shared/Campo.vue';
  import Input from '@/components/shared/Input.vue';
  import Multiselect from '@/components/shared/Multiselect.vue';
  import DatePicker from '@/components/shared/DatePicker.vue';
  import Money from '@/components/shared/Money.vue';
  import ValidacoesExtension from '@/core/validations/validacoes';

  import Docs from '@/components/clientes/cadastro/identificacao/Docs.vue';

  import { vxm } from '@/store/index';

  import { EstadoCivil } from '@/core/enums/pessoa';

  export default defineComponent({
    name: 'CadastroPessoaFisica',
    components: {
      Campo,
      Input,
      Multiselect,
      DatePicker,
      Money,
      Docs,
    },
    setup() {
      const { isBloquearAlterarIdentidade, isBloquearAlterarLimiteCompraRenda } = vxm.parametros.permissoesUsuario;
      const { isObrigatorioDataNascimento, isObrigatorioSexo } = vxm.parametros.parametrosCrm;

      const isBloquearAlteracaoDadosIdentidade = computed(() => {
        return !vxm.cadastro.isNovoCadastroPessoa && isBloquearAlterarIdentidade;
      });

      const isBloquearAlterarNomeMae = computed((): boolean => {
        return vxm.parametros.permissoesUsuario.isBloquearAlterarNomeMae && !vxm.cadastro.isNovoCadastroPessoa;
      });

      const isBloquearAlterarNomePai = computed((): boolean => {
        return vxm.parametros.permissoesUsuario.isBloquearAlterarNomePai && !vxm.cadastro.isNovoCadastroPessoa;
      });

      const isBloquearAlterarDataNascimento = computed((): boolean => {
        return vxm.parametros.permissoesUsuario.isBloquearAlterarDataNascimento && !vxm.cadastro.isNovoCadastroPessoa;
      });

      const isShowNomeConjuge = computed((): boolean => {
        vxm.cadastro.pessoa.pessoaFisica.nomeConjuge.valor.replace(/[^a-zA-Z0-9 ]/g, "");
        return vxm.cadastro.pessoa.pessoaFisica.estadoCivil.valor === EstadoCivil.casado
        || vxm.cadastro.pessoa.pessoaFisica.estadoCivil.valor === EstadoCivil.concubinato;
      });

      const limparNomeConjuge = () => {
        vxm.cadastro.pessoa.pessoaFisica.nomeConjuge.valor = "";
      };

      return {
        ...vxm,
        isBloquearAlteracaoDadosIdentidade,
        isObrigatorioDataNascimento,
        isObrigatorioSexo,
        isBloquearAlterarLimiteCompraRenda,
        isBloquearAlterarNomeMae,
        isBloquearAlterarNomePai,
        isBloquearAlterarDataNascimento,
        isShowNomeConjuge,
        limparNomeConjuge,
      };
    },
  });
